<template>
    <div>
        <!-- <h2>Position Mapping</h2> -->
        <select-customer v-on:customer-selected="customerSelected" v-if="!isCustomerUser"></select-customer>

        <!-- <v-form ref="form" v-model="valid" lazy-validation v-if="!isCustomerUser">
              <v-container>
                    <v-row>
                        <v-col cols="12" sm="3" md="3">
                            <v-menu
                                :close-on-content-click="false"
                                :nudge-width="200"
                                offset-x>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                        color="green"
                                        dark
                                        v-bind="attrs"
                                        class="mt-2 ml-2"
                                        v-on="on">
                                        <v-icon  class="mr-2" large>mdi-account</v-icon>
                                        {{selectedCustomer.text}}
                                        </v-btn>
                                    </template>
                                    <v-card
                                    class="mx-auto"
                                    max-width="500">
                                        <v-toolbar
                                        flat
                                        color="transparent">
                                            <v-toolbar-title>Select Customer</v-toolbar-title>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                            icon
                                            @click="$refs.search.focus()">
                                                <v-icon>mdi-magnify</v-icon>
                                            </v-btn>
                                        </v-toolbar>
                                         <v-container class="py-0">
                                            <v-row
                                            align="center"
                                            justify="start">
                                                <v-col
                                                v-for="(selection, i) in selections"
                                                :key="selection.text"
                                                class="shrink">
                                                    <v-chip
                                                        :disabled="loading"
                                                        close
                                                        @click:close="selected.splice(i, 1)">
                                                        <v-icon
                                                        left
                                                        v-text="selection.icon"
                                                        ></v-icon>
                                                        {{ selection.text }}
                                                    </v-chip>
                                                </v-col>
                                                <v-col
                                                v-if="!allSelected"
                                                cols="12"
                                                >
                                                <v-text-field
                                                    ref="search"
                                                    v-model="searchItem"
                                                    full-width
                                                    hide-details
                                                    label="Search"
                                                    single-line
                                                ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                        <v-list>
                                            <template v-for="item in categories">
                                                <v-list-item
                                                :key="item.customerNumber"
                                                :disabled="loading"
                                                v-if="!selected.includes(item)"
                                                @click="SetselectedCustomer(item)">
                                                <v-list-item-avatar>
                                                    <v-icon
                                                    :disabled="loading"
                                                    v-text="item.icon"></v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-title v-text="item.text"></v-list-item-title>
                                                </v-list-item>
                                            </template>
                                        </v-list>
                                    </v-card>
                                </v-menu>
                        </v-col>
                        <v-col cols="12" sm="2" md="2">
                            <v-btn
                            class="ma-2"
                            outlined
                            small
                            fab
                            color="green">
                                <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
              </v-container>
         </v-form> -->
        <v-card v-else>
            <h3>{{ this.$store.getters.customer }}</h3>

        </v-card>

        <v-container color="primary">
            <v-card icon="clipboard-text" title="Positions" class="elevation-1">
                <v-card-title>
                    <v-text-field v-model="searchkey" append-icon="mdi-magnify" label="Search" single-line hide-details>
                    </v-text-field>
                </v-card-title>
                <v-data-table :headers="PositionGridFields" :items="items" ref="dtPositions" :search="searchkey"
                    class="elevation-1">
                    <template v-slot:[`header.Actions`]="{ header }">
                        <h6><b> {{ header.text }}</b> </h6>
                    </template>
                    <template v-slot:[`header.jobName`]="{ header }">
                        <h6><b> {{ header.text }}</b> </h6>
                    </template>
                    <template v-slot:[`header.mappedJob`]="{ header }">
                        <h6><b> {{ header.text }}</b> </h6>
                    </template>
                    <template v-slot:[`header.jobType`]="{ header }">
                        <h6><b> {{ header.text }}</b> </h6>
                    </template>
                    <template v-slot:[`header.workLocation`]="{ header }">
                        <h6><b> {{ header.text }}</b> </h6>
                    </template>
                    <template v-slot:[`header.department`]="{ header }">
                        <h6><b> {{ header.text }}</b> </h6>
                    </template>
                    <template v-slot:[`header.subdepartment`]="{ header }">
                        <h6><b> {{ header.text }}</b> </h6>
                    </template>
                    <template v-slot:[`header.totalExperienceYearsInCompany`]="{ header }">
                        <h6><b> {{ header.text }}</b> </h6>
                    </template>
                    <template v-slot:[`header.totalExperienceYearsInJobInCompany`]="{ header }">
                        <h6><b> {{ header.text }}</b> </h6>
                    </template>
                    <template v-slot:[`header.totalExperienceYearsInJobBeforeCompany`]="{ header }">
                        <h6><b> {{ header.text }}</b> </h6>
                    </template>
                    <template v-slot:[`header.actualGrossBasicPay`]="{ header }">
                        <h6><b> {{ header.text }}</b> </h6>
                    </template>
                    <template v-slot:[`header.totalAllowances`]="{ header }">
                        <h6><b> {{ header.text }}</b> </h6>
                    </template>
                    <template v-slot:[`header.annualTotalGuaranteedPay`]="{ header }">
                        <h6><b> {{ header.text }}</b> </h6>
                    </template>
                    <template v-slot:[`header.totalSTIPerformanceRelated`]="{ header }">
                        <h6><b> {{ header.text }}</b> </h6>
                    </template>
                    <template v-slot:[`header.totalSTINonPerformanceRelated`]="{ header }">
                        <h6><b> {{ header.text }}</b> </h6>
                    </template>
                    <template v-slot:[`header.totalSTI`]="{ header }">
                        <h6><b> {{ header.text }}</b> </h6>
                    </template>
                    <template v-slot:[`header.totalSTIValueEGP`]="{ header }">
                        <h6><b> {{ header.text }}</b> </h6>
                    </template>
                    <template v-slot:[`header.totalLTI`]="{ header }">
                        <h6><b> {{ header.text }}</b> </h6>
                    </template>
                    <template v-slot:[`header.totalLTIValueEGP`]="{ header }">
                        <h6><b> {{ header.text }}</b> </h6>
                    </template>
                    <template v-slot:[`header.annualTotalTargetPayValueEGP`]="{ header }">
                        <h6><b> {{ header.text }}</b> </h6>
                    </template>
                    <template v-slot:top>
                        <v-dialog v-model="EditTotalAllowanceDialog" max-width="800px">
                            <v-card>
                                <v-card-title>
                                    <span class="text-h5">Total Allowances Details</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-form ref="totalAllowanceForm" v-model="validTotalAllowanceForm">
                                        <v-container>
                                            <v-row>
                                                <v-col cols="4" sm="4" md="4">
                                                    <v-text-field type="number" v-model.number="editedItem.natureOfJobAllowance"
                                                        :rules="[rules.required, rules.requiredNumber]"
                                                        label="Nature of Job Allowance">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="4" sm="4" md="4">
                                                    <v-text-field type="number" v-model.number="editedItem.representationAllowance"
                                                        :rules="[rules.required, rules.requiredNumber]"
                                                        label="Representation Allowance">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="4" sm="4" md="4">
                                                    <v-text-field type="number" v-model.number="editedItem.housingRentAllowance"
                                                        :rules="[rules.required, rules.requiredNumber]"
                                                        label="Housing/Rent Allowance">
                                                    </v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="4" sm="4" md="4">
                                                    <v-text-field type="number" v-model.number="editedItem.transportaionAllowance"
                                                        :rules="[rules.required,  rules.requiredNumber]"
                                                        label="Transportation Allowance">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="4" sm="4" md="4">
                                                    <v-text-field type="number" v-model.number="editedItem.carAllowance"
                                                        :rules="[rules.required, rules.requiredNumber]"
                                                        label="Car Allowance">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="4" sm="4" md="4">
                                                    <v-text-field type="number" v-model.number="editedItem.mobileAllowance"
                                                        :rules="[rules.required, rules.requiredNumber]"
                                                        label="Mobile Allowance">
                                                    </v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="6" sm="6" md="6">
                                                    <v-text-field type="number" v-model.number="editedItem.otherAllowance"
                                                        :rules="[rules.required, rules.requiredNumber]"
                                                        label="Other Allowance">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="6" sm="6" md="6">
                                                    <v-text-field type="number" v-model.number="editedItem.schooling"
                                                        :rules="[rules.required, rules.requiredNumber]"
                                                        label="Schooling">
                                                    </v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-form>
                                </v-card-text>
                                <v-card-actions>
                                    <h4>Total Allowance <b>{{ calculateTotalAllowance }}</b> </h4>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="EditTotalAllowanceDialog = false">
                                        Cancel</v-btn>
                                    <v-btn color="blue darken-1" :disabled="!validTotalAllowanceForm" text
                                        @click="validateTotalAllowance">Save</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="EditTotalSTIPerformanceDialog" max-width="800px">
                            <v-card>
                                <v-card-title>
                                    <span class="text-h5">Short-Term Incentive (STI) performance Related Details</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-form ref="STIPerformanceForm" v-model="validTotalSTIPerformanceForm">
                                        <v-container>
                                            <v-row>
                                                <v-col cols="4" sm="4" md="4">
                                                    <v-text-field type="number" v-model="editedItem.eoyBonus" prefix="%"
                                                        :rules="[rules.required, rules.requiredNumber]" required
                                                        label="End-Of-Year Bonus">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="4" sm="4" md="4">
                                                    <v-text-field type="number" v-model="editedItem.eoyProfitShare"
                                                        prefix="%" :rules="[rules.required, rules.requiredNumber]" required
                                                        label="End-Of-Year Profit Share">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="4" sm="4" md="4">
                                                    <v-text-field type="number" v-model="editedItem.annualSalesCommission"
                                                        prefix="%" :rules="[rules.required, rules.requiredNumber]" required
                                                        label="Annual Sales Commission">
                                                    </v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="4" sm="4" md="4">
                                                    <v-text-field type="number" v-model="editedItem.projectBasedBonus"
                                                        prefix="%" :rules="[rules.required, rules.requiredNumber]" required
                                                        label="Project Based Bonus">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="4" sm="4" md="4">
                                                    <v-text-field type="number" v-model="editedItem.productivityBasedBonus"
                                                        prefix="%" :rules="[rules.required, rules.requiredNumber]" required
                                                        label="Productivity Based Bonus">
                                                    </v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-form>
                                </v-card-text>
                                <v-card-actions>
                                    <h4>Total Short-Term Incentive (STI) Performance Related <b>{{
                                        calculateTotalSTIPerformance }}</b> </h4>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="EditTotalSTIPerformanceDialog = false">
                                        Cancel</v-btn>
                                    <v-btn color="blue darken-1" :disabled="!valid" text
                                        @click="validateTotalSTIPerformance">Save</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="EditTotalSTINonPerformanceDialog" max-width="800px">
                            <v-card>
                                <v-card-title>
                                    <span class="text-h5">Short-Term Incentive (STI) Non-performance Related Details</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-form ref="TotalSTINonPerformanceForm" v-model="validTotalSTINonPerformanceForm">
                                        <v-container>
                                            <v-row>
                                                <v-col cols="4" sm="4" md="4">
                                                    <v-text-field type="number" v-model="editedItem.annualSeasonalBonus"
                                                        prefix="%" :rules="[rules.required, rules.requiredNumber]" required
                                                        label="Annual Seasonal Bonus">
                                                    </v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="4" sm="4" md="4">
                                                    <v-text-field type="number" v-model="editedItem.otherBonus" prefix="%"
                                                        :rules="[rules.required, rules.requiredNumber]" required
                                                        label="Other Bonus">
                                                    </v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-form>
                                </v-card-text>
                                <v-card-actions>
                                    <h4>Total Short-Term Incentive (STI) Non-Performance Related <b>{{
                                        calculateTotalSTINonPerformance | formatNumber }}</b> </h4>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="EditTotalSTINonPerformanceDialog = false">
                                        Cancel</v-btn>
                                    <v-btn color="blue darken-1" :disabled="!valid" text
                                        @click="validateTotalSTINonPerformance">Save</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="EditTotalLTIeDialog" max-width="800px">
                            <v-card>
                                <v-card-title>
                                    <span class="text-h5">Long-Term Incentive (LTI) Details</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-form ref="LTIForm" v-model="validLTIForm">
                                        <v-container>
                                            <v-row>
                                                <v-col cols="4" sm="4" md="4">
                                                    <v-text-field type="number" v-model="editedItem.stockOptionsPlan"
                                                        prefix="%" :rules="[rules.required, rules.requiredNumber]" required
                                                        label="Employee Stock Options Plan">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="4" sm="4" md="4">
                                                    <v-text-field type="number" v-model="editedItem.retentionSharesPlan"
                                                        prefix="%" :rules="[rules.required, rules.requiredNumber]" required
                                                        label="Retention Share plan">
                                                    </v-text-field>
                                                </v-col>

                                            </v-row>
                                            <v-row>
                                                <v-col cols="4" sm="4" md="4">
                                                    <v-text-field type="number" v-model="editedItem.tenureOfServiceBonus"
                                                        prefix="%" :rules="[rules.required, rules.requiredNumber]" required
                                                        label="Tenure of Service Bonus">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="4" sm="4" md="4">
                                                    <v-text-field type="number" v-model="editedItem.employeeSavingPlan"
                                                        prefix="%" :rules="[rules.required, rules.requiredNumber]" required
                                                        label="Employee Saving Plan">
                                                    </v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-form>
                                </v-card-text>
                                <v-card-actions>
                                    <h4>Total Long-Term Incentive (LTI) <b>{{ calculateTotalLTI | formatNumber }}</b> </h4>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="EditTotalLTIeDialog = false"> Cancel</v-btn>
                                    <v-btn color="blue darken-1" :disabled="!valid" text
                                        @click="validateTotalLTI">Save</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="EditBasicInfoDialog" max-width="800px">
                            <v-card>
                                <v-card-title>
                                    <span class="text-h5">Position Mapping Info for ( <b>{{ editedItem.jobName }}</b>
                                        )</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-container id="user-profile" fluid tag="section">
                                        <v-row>
                                            <v-col cols="12" md="12">
                                                <v-stepper class="mt-12" non-linear>
                                                    <v-stepper-header>
                                                        <v-stepper-step editable step="1">
                                                            Basic Info
                                                        </v-stepper-step>
                                                        <v-divider></v-divider>
                                                        <v-stepper-step editable step="2">
                                                            Financial Info
                                                        </v-stepper-step>
                                                    </v-stepper-header>
                                                    <v-stepper-items>
                                                        <v-stepper-content step="1">
                                                            <v-form ref="BasicInfoForm" v-model="validLTIForm">
                                                                <v-container>
                                                                    <v-row>
                                                                        <v-col cols="6" sm="6" md="6">
                                                                            <v-text-field v-model="editedItem.mappedJob"
                                                                                :rules="[rules.required]" required
                                                                                label="Mapped Job">
                                                                            </v-text-field>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row>
                                                                        <v-col cols="12" sm="6" md="6">
                                                                            <v-select v-model="editedItem.jobType"
                                                                                :items="JobType" :rules="[rules.required]"
                                                                                label="Job Family" required>
                                                                            </v-select>
                                                                        </v-col>
                                                                        <!-- <v-col cols="12" sm="6" md="6">
                                                                                <v-select
                                                                                    v-model="editedItem.reportsTo"
                                                                                    :items="allPositions"
                                                                                    :rules="[rules.required,rules.reportsTo]"
                                                                                    label="Reports To"
                                                                                    required>
                                                                                </v-select>
                                                                            </v-col> -->
                                                                    </v-row>
                                                                    <v-row>
                                                                        <v-col cols="12" sm="4" md="4">
                                                                            <v-select v-model="editedItem.workLocation"
                                                                                :items="allWorkLocations"
                                                                                :rules="[rules.required]"
                                                                                label="Work Location"
                                                                                @change="workLocationChanged" required>
                                                                            </v-select>
                                                                        </v-col>
                                                                        <v-col cols="12" sm="4" md="4">
                                                                            <v-select v-model="editedItem.department"
                                                                                :items="allDepartments"
                                                                                :rules="[rules.required]" label="Department"
                                                                                :disabled="allDepartments.length == 0"
                                                                                @change="departmentChanged" required>
                                                                            </v-select>
                                                                        </v-col>
                                                                        <v-col cols="12" sm="4" md="4">
                                                                            <v-select v-model="editedItem.subDepartment"
                                                                                :items="allSubDepartments"
                                                                                label="Sub-Department"
                                                                                :disabled="allDepartments.length == 0 || allSubDepartments.length == 0"
                                                                                required>
                                                                            </v-select>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <!-- <v-row>
                                                                            <v-col cols="12" sm="4" md="4">
                                                                                <h5>Organization Layer: <b>{{editedItem.orgLayer}}</b> </h5>
                                                                            </v-col>
                                                                        </v-row> -->
                                                                    <v-row>
                                                                        <v-col cols="6" sm="6" md="6">
                                                                            <v-text-field v-model="editedItem.jobGrade"
                                                                                :rules="[rules.required]" required
                                                                                label="Job Grade">
                                                                            </v-text-field>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row>
                                                                        <v-col cols="12" sm="12" md="12">
                                                                            <v-text-field
                                                                                v-model="editedItem.totalExperienceYearsInCompany"
                                                                                :rules="[rules.required]" required
                                                                                type="number"
                                                                                label="Total years of experience in the company">
                                                                            </v-text-field>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row>
                                                                        <v-col cols="12" sm="12" md="12">
                                                                            <v-text-field
                                                                                v-model="editedItem.totalExperienceYearsInJobInCompany"
                                                                                :rules="[rules.required]" required
                                                                                type="number"
                                                                                label="Total years of experience in the Job in the company">
                                                                            </v-text-field>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row>
                                                                        <v-col cols="12" sm="12" md="12">
                                                                            <v-text-field
                                                                                v-model="editedItem.totalExperienceYearsInJobBeforeCompany"
                                                                                :rules="[rules.required]" required
                                                                                type="number"
                                                                                label="Total years of experience in the same Job before the company">
                                                                            </v-text-field>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row>
                                                                        <v-col cols="8" sm="8" md="8">
                                                                        </v-col>
                                                                        <v-col cols="4" sm="4" md="4">
                                                                            <v-btn color="blue darken-1" text
                                                                                @click="EditBasicInfoDialog = false">
                                                                                Cancel</v-btn>
                                                                            <v-btn color="blue darken-1" :disabled="!valid"
                                                                                text @click="validateBasicInfo">Save</v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-container>
                                                            </v-form>
                                                        </v-stepper-content>
                                                        <v-stepper-content step="2">
                                                            <v-form ref="ActualGrossPayForm" v-model="validBasicSalaryForm">
                                                                <v-container>
                                                                    <v-row>
                                                                        <v-col cols="10" sm="10" md="10">
                                                                            <v-text-field
                                                                                v-model="editedItem.actualGrossBasicPay"
                                                                                :rules="[rules.required]" required
                                                                                type="number"
                                                                                label="Annual Actual Gross Basic Pay">
                                                                            </v-text-field>
                                                                        </v-col>
                                                                        <v-col cols="2" sm="2" md="2">
                                                                            <v-btn color="blue darken-1" :disabled="!valid"
                                                                                text
                                                                                @click="saveActualGrossPay">Save</v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-container>
                                                            </v-form>
                                                            <v-container>
                                                                <v-row>
                                                                    <v-col cols="6" sm="6" md="6">
                                                                        Annual Total Allowances:
                                                                    </v-col>
                                                                    <v-col cols="3" sm="3" md="3">
                                                                        <b>{{ editedItem.totalAllowances | formatNumber
                                                                        }}</b>
                                                                    </v-col>
                                                                    <v-col cols="2" sm="2" md="2">
                                                                        <v-btn x-small
                                                                            @click="editTotalAllowance(editedItem)">
                                                                            details... </v-btn>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row>
                                                                    <v-col cols="6" sm="6" md="6">
                                                                        Annual Total Guaranteed Pay:
                                                                    </v-col>
                                                                    <v-col cols="3" sm="3" md="3">
                                                                        <b>{{ editedItem.annualTotalGuaranteedPay |
                                                                            formatNumber }}</b>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row>
                                                                    <v-col cols="6" sm="6" md="6">
                                                                        Annual Total Short-Term Incentive (STI) Performance
                                                                        Related:
                                                                    </v-col>
                                                                    <v-col cols="3" sm="3" md="3">
                                                                        <b>% {{ editedItem.totalSTIPerformanceRelated |
                                                                            formatNumber }}</b>
                                                                    </v-col>
                                                                    <v-col cols="2" sm="2" md="2">
                                                                        <v-btn x-small
                                                                            @click="EditTotalSTIPerformance(editedItem)">
                                                                            details... </v-btn>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row>
                                                                    <v-col cols="6" sm="6" md="6">
                                                                        Annual Total Short-Term Incentive (STI) Non-Performance
                                                                        Related:
                                                                    </v-col>
                                                                    <v-col cols="3" sm="3" md="3">
                                                                        <b>% {{ editedItem.totalSTINonPerformanceRelated |
                                                                            formatNumber }}</b>
                                                                    </v-col>
                                                                    <v-col cols="2" sm="2" md="2">
                                                                        <v-btn x-small
                                                                            @click="EditTotalSTINonPerformance(editedItem)">
                                                                            details... </v-btn>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row>
                                                                    <v-col cols="6" sm="6" md="6">
                                                                        Annual Total Short-Term Incentive (STI)
                                                                    </v-col>
                                                                    <v-col cols="3" sm="3" md="3">
                                                                        <b>% {{ editedItem.totalSTI | formatNumber }}</b>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row>
                                                                    <v-col cols="6" sm="6" md="6">
                                                                        Annual Total Short-Term Incentive (STI) Value in EGP
                                                                    </v-col>
                                                                    <v-col cols="2" sm="2" md="2">
                                                                        <b>{{ editedItem.totalSTIValueEGP | formatNumber
                                                                        }}</b>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row>
                                                                    <v-col cols="6" sm="6" md="6">
                                                                        Annual Total Long-Term Incentive (LTI):
                                                                    </v-col>
                                                                    <v-col cols="3" sm="3" md="3">
                                                                        <b>% {{ editedItem.totalLTI | formatNumber }}</b>
                                                                    </v-col>
                                                                    <v-col cols="2" sm="2" md="2">
                                                                        <v-btn x-small @click="EditTotalLTI(editedItem)">
                                                                            details... </v-btn>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row>
                                                                    <v-col cols="6" sm="6" md="6">
                                                                        Annual Total Long-Term Incentive (LTI) Value in EGP:
                                                                    </v-col>
                                                                    <v-col cols="3" sm="3" md="3">
                                                                        <b>{{ editedItem.totalLTIValueEGP | formatNumber
                                                                        }}</b>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row>
                                                                    <v-col cols="6" sm="6" md="6">
                                                                        Annual Total Target Pay:
                                                                    </v-col>
                                                                    <v-col cols="3" sm="3" md="3">
                                                                        <b>{{ editedItem.annualTotalTargetPayValueEGP |
                                                                            formatNumber }}</b>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-container>
                                                        </v-stepper-content>
                                                    </v-stepper-items>
                                                </v-stepper>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </template>
                    <!-- <template  v-slot:[`header.totalAllowances`]="{ header }">
                        {{ header.text.toUpperCase() }}
                    </template> -->
                    <template v-slot:[`item.jobName`]="{ item }">
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                {{ item.jobName }}
                            </v-col>
                        </v-row>
                    </template>

                    <template v-slot:[`item.totalAllowances`]="{ item }">
                        <v-row>
                            <v-col cols="6" sm="6" md="6">
                                {{ item.totalAllowances | formatNumber }}
                            </v-col>
                            <v-col cols="2" sm="2" md="2">
                                <v-btn x-small @click="editTotalAllowance(item)"> details... </v-btn>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:[`item.annualTotalGuaranteedPay`]="{ item }">
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                {{ item.annualTotalGuaranteedPay | formatNumber }}
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:[`item.totalSTIPerformanceRelated`]="{ item }">
                        <v-row>
                            <v-col cols="6" sm="6" md="6">
                                {{ item.totalSTIPerformanceRelated | formatNumber }}
                            </v-col>
                            <v-col cols="2" sm="2" md="2">
                                <v-btn x-small @click="EditTotalSTIPerformance(item)"> details... </v-btn>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:[`item.totalSTINonPerformanceRelated`]="{ item }">
                        <v-row>
                            <v-col cols="6" sm="6" md="6">
                                {{ item.totalSTINonPerformanceRelated | formatNumber }}
                            </v-col>
                            <v-col cols="2" sm="2" md="2">
                                <v-btn x-small @click="EditTotalSTINonPerformance(item)"> details... </v-btn>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:[`item.totalSTI`]="{ item }">
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                {{ item.totalSTI | formatNumber }}
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:[`item.totalSTIValueEGP`]="{ item }">
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                {{ item.totalSTIValueEGP | formatNumber }}
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:[`item.totalLTI`]="{ item }">
                        <v-row>
                            <v-col cols="6" sm="6" md="6">
                                {{ item.totalLTI | formatNumber }}
                            </v-col>
                            <v-col cols="2" sm="2" md="2">
                                <v-btn x-small @click="EditTotalLTI(item)"> details... </v-btn>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:[`item.totalLTIValueEGP`]="{ item }">
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                {{ item.totalLTIValueEGP | formatNumber }}
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:[`item.annualTotalTargetPayValueEGP`]="{ item }">
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                {{ item.annualTotalTargetPayValueEGP | formatNumber }}
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:[`item.Actions`]="{ item }">
                        <v-icon small class="mr-2" @click="editBasicInfo(item)">
                            mdi-pencil
                        </v-icon>
                        <div v-if="item.isMapped == true && item.isAdminApproved == true"> Mapped </div>
                        <div
                            v-if="item.isMapped == true && item.isSubmittedForApprove == true && item.isAdminApproved == false">
                            Waiting For approve </div>
                        <v-btn x-small @click="validateMapJob(item)" v-if="!item.isMapped"> Map </v-btn>
                    </template>
                    <template v-slot:[`item.jobType`]="{ item }">
                        {{ mapJobType(item.jobType) }}
                    </template>
                    <template slot="no-data">
                        <h2>No jobs to display</h2>
                    </template>
                </v-data-table>
            </v-card>
        </v-container>




        <div class="text-center">
            <v-snackbar v-model="snackbar" timeout="3000">
                {{ alertMsg }}
                <template v-slot:action="{ attrs }">
                    <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
                        Close
                    </v-btn>
                </template>
            </v-snackbar>
        </div>


        <form ref="form" @submit.stop.prevent="PositionSearch">
            <b-container fluid class="searchPart">


            </b-container>
        </form>
        <hr />

    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import SelectCustomer from './SelectCustomer';

export default {
    components: {
        SelectCustomer
    },
    mixins: [validationMixin],
    computed: {
        calculateTotalAllowance() {
            let val =

                Number(this.editedItem.natureOfJobAllowance ?? 0) +
                Number(this.editedItem.representationAllowance ?? 0) +
                Number(this.editedItem.housingRentAllowance ?? 0) +
                Number(this.editedItem.transportaionAllowance ?? 0) +
                Number(this.editedItem.carAllowance ?? 0) +
                Number(this.editedItem.mobileAllowance ?? 0) +
                Number(this.editedItem.otherAllowance ?? 0) +
                Number(this.editedItem.schooling ?? 0);
            if (val != null && val > 0) {
                this.updateTotalAllowanceValue(val);
                this.updateRecord();
            }
            return this.editedItem.totalAllowances;
        },
        calculateTotalSTIPerformance() {
            let val =
                Number(this.editedItem.eoyBonus) +
                Number(this.editedItem.eoyProfitShare) +
                Number(this.editedItem.annualSalesCommission) +
                Number(this.editedItem.projectBasedBonus) +
                Number(this.editedItem.productivityBasedBonus)
            if (val != null && val > 0)
                this.updateTotalSTIPerformanceValue(val);
            return val;
        },
        calculateTotalSTINonPerformance() {
            let val = Number(this.editedItem.annualSeasonalBonus) +
                Number(this.editedItem.otherBonus);
            if (val != null && val > 0)
                this.updateTotalSTINonPerformanceValue(val);
            return val
        },
        calculateTotalLTI() {
            let val =
                Number(this.editedItem.stockOptionsPlan) +
                Number(this.editedItem.retentionSharesPlan) +
                Number(this.editedItem.tenureOfServiceBonus) +
                Number(this.editedItem.employeeSavingPlan)
            if (val != null && val > 0)
                this.updateTotalLTIValue(val);
            return val;
        },
        allSelected() {
            return this.selected.length === this.newCustomerList.length
        },
        selections() {
            const selections = []

            for (const selection of this.selected) {
                selections.push(selection)
            }

            return selections
        },
        categories() {
            const search = this.searchItem.toLowerCase()

            if (!search) return this.newCustomerList

            return this.newCustomerList.filter(item => {
                const text = item.text.toLowerCase()

                return text.indexOf(search) > -1
            })
        },
        isCustomerUser() {
            return this.$store.getters.customer != 'No Customer';
        },
        customerNumber() {
            if (this.$store.state.userDetails.subscriptionId != -1) {
                return this.$store.state.userDetails.subscriptionId;
            }
            else if (this.search.customer > 0) {
                return this.search.customer
            }
            else
                return -1;

        },
        surveyYear() {
            return this.$store.state.userDetails.surveyYear;
        },
        availablePositions() {
            if (this.PositionsInSearch.length > 0) {
                return this.PositionsInSearch.filter(opt => this.search.selectedPositions.indexOf(opt) === -1);
            }
            else {
                return [];
            }
        },
        rows() {
            return this.MappedUnmapped.length
        }
    },
    watch: {
        selected() {
            this.searchItem = ''
        },
    },
    data() {
        return {
            validBasicSalaryForm: false,
            searchkey: '',
            alertMsg: '',
            snackbar: false,
            PositionGridFields: [
                { value: 'Actions', text: 'Actions', width: '100' },
                { value: 'jobName', text: 'Job Name', width: '200' },
                { value: 'mappedJob', text: 'Mapped Job Name', width: '200' },
                { value: 'jobType', text: 'Job Type', width: '150' },
                // {value: 'reportsTo', text: 'Reports To' },
                { value: 'workLocation', text: 'Work Location', width: '150' },
                // {value: 'workcity', text: 'City' },
                { value: 'department', text: 'Department', width: '150' },
                { value: 'subdepartment', text: 'Sub-Department', width: '150' },
                { value: 'totalExperienceYearsInCompany', text: 'Total Years Of Experince in The Company', width: '300' },
                { value: 'totalExperienceYearsInJobInCompany', text: 'Total Years Of Experince in Job The Company', width: '350' },
                { value: 'totalExperienceYearsInJobBeforeCompany', text: 'Total Years Of Experince in Job Before The Company', width: '400' },
                { value: 'actualGrossBasicPay', text: 'Actual Gross Basic Pay', width: '200' },
                { value: 'totalAllowances', text: 'Total Allowances', width: '200' },
                { value: 'annualTotalGuaranteedPay', text: 'Annual Total Guaranteed Pay (TGP)', width: '300' },
                { value: 'totalSTIPerformanceRelated', text: 'Total STI Performance Related', width: '250' },
                { value: 'totalSTINonPerformanceRelated', text: 'Total STI Non-Performance Related', width: '300' },
                { value: 'totalSTI', text: 'Total STI', width: '200' },
                { value: 'totalSTIValueEGP', text: 'Total Gross Annual STI Value in EGP ', width: '300' },
                { value: 'totalLTI', text: 'Total LTI', width: '200' },
                { value: 'totalLTIValueEGP', text: 'Total Gross Annual LTI Value in EGP ', width: '300' },
                { value: 'annualTotalTargetPayValueEGP', text: 'Annual TOtal Target Pay Value in EGP', width: '300' },
            ],
            // PositionGridFields:[
            //      { text: '10th Percentile', value: '10th' },
            //      { text: '1st Quartile', value: '1st' },
            //      { text: 'Median', value: 'median' },
            //      { text: 'Average', value: 'avg' },
            //      { text: '3rd Quartile', value: 'avg' },
            // ],
            items: [],
            orgChartItems: [],
            editedIndex: -1,
            selectedSurveyYear: -1,
            EditTotalAllowanceDialog: false,
            EditBasicInfoDialog: false,
            EditTotalSTIPerformanceDialog: false,
            EditTotalSTINonPerformanceDialog: false,
            EditTotalLTIeDialog: false,
            validTotalAllowanceForm: false,
            validTotalSTIPerformanceForm: true,
            validTotalSTINonPerformanceForm: true,
            validLTIForm: true,
            searchItem: '',
            newCustomerList: [],
            selected: [],
            valid: true,
            loading: false,
            currentPage: 1,
            perPage: 3,
            selectedCustomer: {
                text: 'Select Customer',
                customerNumber: -1
            },
            MappedUnmapped: [
                // { UnmappedPosition: 'Accountant' },
                // { UnmappedPosition: 'HR' },
            ],
            fields: [
                { key: 'customerName', label: 'Subscription', sortable: true, },
                { key: 'jobName', label: 'Job Name', sortable: true, },
                // { key: 'positionTypeName', label: 'Job Family',sortable: true,},
                'Details',
            ],
            rules: {
                required: value => !!value || value == 0 || 'Required Field',
                requiredNumber: value => value >= 0 || 'value should be >= 0',
                reportsTo: value => value != this.editedItem.jobName || 'cannot report to same position'
            },
            positionAfterError: false,
            positionTypeNameError: false,
            positionDepartmentError: false,
            positionCountryError: false,
            basicSalaryError: false,


            isBasicDataEditMode: false,
            isBasicSalaryEditMode: false,
            isActualAnnualAllowanceEditMode: false,
            isPerformanceRelatedBonusEditMode: false,
            isNonPerformanceRelatedBonusEditMode: false,
            isLongTermIncentiveEditMode: false,
            isWorkRelatedRewardsEditMode: false,
            JobType: [
                { text: "Enabler Jobs", value: 1 },
                { text: "Core Jobs", value: 2 },
                { text: "Support Jobs", value: 3 },
            ],
            value: [],
            emptyValue: 'Empty',
            PositionKeyword: null,
            PositionsInSearch: [],
            jobTypeOpOptions: [],
            jobsList: [],
            departmentsList: [],
            subDepartmentsList: [],
            organizationLayers: [],
            jobGrades: [],
            workLocations: [],
            customerList: [],
            surveyYears: [],
            search: {
                customer: null,
                surveyYear: null,
                selectedPositions: null,
                positionIncluded: 1
            },
            allPositions: [],
            allWorkLocations: [],
            allDepartments: [],
            allSubDepartments: [],
            allOrgLayers: [],
            editedItem: {
                id: null,
                customerNumber: 5,
                companyCode: 20,
                surveyYear: 2021,
                jobType: null,
                jobTitleBefore: null,
                jobTitleAfter: null,
                reportsTo: null,
                department: null,
                subDepartment: null,
                orgLayer: null,
                jobGrade: null,
                workLocation: null,
                companyYearsOfExperience: null,
                companyJobYearsOfExperience: null,
                jobYearsOfExperienceBefore: null,
                natureOfJob: null,
                representation: null,
                housingRent: null,
                transportation: null,
                car: null,
                mobileAllowance: null,
                otherAllowance: null,
                EOYBonus: null,
                EOYProfitShare: null,
                annualSalesCommission: null,
                projectBasedBonus: null,
                productivityBasedBonus: null,
                annualSeasonalBonus: null,
                otherBonus: null,
                stockOptionsPlan: null,
                retentionSharesPlan: null,
                tenureOfServiceBonus: null,
                schooling: null,
                employeeSavingPlan: null,
                annualPaidVacation: null,
                networking: null,
                avgOvertimeHoursPerMonth: null,
                overtimeRate: null,
            },
            PositionOptions: [
                { text: "UnMapped Positoins only", value: 1 },
                { text: "Mapped Positions only", value: 2 }
            ],
            customerLookups: [],
        }
    },
    validations: {
        form: {
            jobTitleAfter: {
                required
            }
        }
    },
    methods: {
        customerSelected(cn) {
            let sCustomer = {
                text: cn.customerName,
                customerNumber: cn.customerNumber
            };
            this.SetselectedCustomer(sCustomer);
        },
        updateTotalAllowanceValue(val) {
            this.editedItem.totalAllowances = val;
        },
        updateTotalSTIPerformanceValue(val) {
            this.editedItem.totalSTIPerformanceRelated = val;
        },
        updateTotalSTINonPerformanceValue(val) {
            this.editedItem.totalSTINonPerformanceRelated = val;
        },
        updateTotalLTIValue(val) {
            this.editedItem.totalLTI = val;
        },
        validateBasicInfo() {
            if (this.$refs.BasicInfoForm.validate()) {
                this.SaveBasicData();
            }
        },
        validateTotalLTI() {
            if (this.$refs.LTIForm.validate()) {
                this.updateLTI();
            }
        },
        updateLTI() {

            let data = {
                Id: this.editedItem.id,
                CustomerNumber: this.editedItem.customerNumber,
                JobId: this.editedItem.jobId,
                SurveyYear: this.surveyYear,
                StockOptionsPlan: this.editedItem.stockOptionsPlan == null ? null : Number(this.editedItem.stockOptionsPlan),
                retentionSharesPlan: this.editedItem.retentionSharesPlan == null ? null : Number(this.editedItem.retentionSharesPlan),
                TenureOfServiceBonus: this.editedItem.tenureOfServiceBonus == null ? null : Number(this.editedItem.tenureOfServiceBonus),
                Schooling: this.editedItem.schooling == null ? null : Number(this.editedItem.schooling),
                EmployeeSavingPlan: this.editedItem.employeeSavingPlan == null ? null : Number(this.editedItem.employeeSavingPlan),

            }

            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/PositionMapping/LongTermIncentive`,
                headers: { 'Content-Type': 'application/json' },
                data: data
            }).then((response) => {
                if (JSON.parse(response.data.status) == true) {
                    this.editedItem.id = response.data.updatedRecord;

                    this.editedItem.totalLTIValueEGP =
                        Number(this.editedItem.grossBasicSalary) *
                        (
                            Number(this.editedItem.stockOptionsPlan) +
                            Number(this.editedItem.retentionSharesPlan) +
                            Number(this.editedItem.tenureOfServiceBonus) +
                            Number(this.editedItem.employeeSavingPlan)
                        ) / 100;

                    this.editedItem.annualTotalTargetPayValueEGP =
                        Number(this.editedItem.grossBasicSalary) *
                        (
                            Number(this.editedItem.stockOptionsPlan) +
                            Number(this.editedItem.retentionSharesPlan) +
                            Number(this.editedItem.tenureOfServiceBonus) +
                            Number(this.editedItem.employeeSavingPlan)
                        ) / 100 +
                        Number(this.editedItem.grossBasicSalary) *
                        (
                            Number(this.editedItem.eoyBonus) +
                            Number(this.editedItem.eoyProfitShare) +
                            Number(this.editedItem.annualSalesCommission) +
                            Number(this.editedItem.projectBasedBonus) +
                            Number(this.editedItem.productivityBasedBonus) +
                            Number(this.editedItem.annualSeasonalBonus) +
                            Number(this.editedItem.otherBonus)
                        ) / 100 +
                        Number(this.editedItem.grossBasicSalary) +
                        Number(this.editedItem.natureOfJobAllowance) +
                        Number(this.editedItem.representationAllowance) +
                        Number(this.editedItem.housingRentAllowance) +
                        Number(this.editedItem.transportaionAllowance) +
                        Number(this.editedItem.carAllowance) +
                        Number(this.editedItem.mobileAllowance) +
                        Number(this.editedItem.otherAllowance);


                    Object.assign(this.items[this.editedIndex], this.editedItem)
                    this.EditTotalLTIeDialog = false;
                    this.alertMsg = "ِLTI updated successfully"
                    this.snackbar = true;

                }
            });

        },
        validateTotalSTINonPerformance() {
            if (this.$refs.TotalSTINonPerformanceForm.validate()) {
                this.updateSTINonPerformance();
            }
        },
        updateSTINonPerformance() {

            let data = {
                Id: this.editedItem.id,
                CustomerNumber: this.editedItem.customerNumber,
                JobId: this.editedItem.jobId,
                SurveyYear: this.surveyYear,
                AnnualSeasonalBonus: this.editedItem.annualSeasonalBonus == null ? null : Number(this.editedItem.annualSeasonalBonus),
                OtherBonus: this.editedItem.otherBonus == null ? null : Number(this.editedItem.otherBonus),
            }

            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/PositionMapping/NonPerformanceRelatedBonus`,
                headers: { 'Content-Type': 'application/json' },
                data: data
            }).then((response) => {
                if (JSON.parse(response.data.status) == true) {
                    this.editedItem.id = response.data.updatedRecord;

                    this.editedItem.totalSTI =
                        Number(this.editedItem.eoyBonus) +
                        Number(this.editedItem.eoyProfitShare) +
                        Number(this.editedItem.annualSalesCommission) +
                        Number(this.editedItem.projectBasedBonus) +
                        Number(this.editedItem.productivityBasedBonus) +
                        Number(this.editedItem.annualSeasonalBonus) +
                        Number(this.editedItem.otherBonus);

                    this.editedItem.totalSTIValueEGP =
                        this.editedItem.grossBasicSalary *
                        (
                            Number(this.editedItem.eoyBonus) +
                            Number(this.editedItem.eoyProfitShare) +
                            Number(this.editedItem.annualSalesCommission) +
                            Number(this.editedItem.projectBasedBonus) +
                            Number(this.editedItem.productivityBasedBonus) +
                            Number(this.editedItem.annualSeasonalBonus) +
                            Number(this.editedItem.otherBonus)
                        ) / 100;

                    this.editedItem.annualTotalTargetPayValueEGP =
                        Number(this.editedItem.grossBasicSalary) *
                        (
                            Number(this.editedItem.stockOptionsPlan) +
                            Number(this.editedItem.retentionSharesPlan) +
                            Number(this.editedItem.tenureOfServiceBonus) +
                            Number(this.editedItem.employeeSavingPlan)
                        ) / 100 +
                        Number(this.editedItem.grossBasicSalary) *
                        (
                            Number(this.editedItem.eoyBonus) +
                            Number(this.editedItem.eoyProfitShare) +
                            Number(this.editedItem.annualSalesCommission) +
                            Number(this.editedItem.projectBasedBonus) +
                            Number(this.editedItem.productivityBasedBonus) +
                            Number(this.editedItem.annualSeasonalBonus) +
                            Number(this.editedItem.otherBonus)
                        ) / 100 +
                        Number(this.editedItem.grossBasicSalary) +
                        Number(this.editedItem.natureOfJobAllowance) +
                        Number(this.editedItem.representationAllowance) +
                        Number(this.editedItem.housingRentAllowance) +
                        Number(this.editedItem.transportaionAllowance) +
                        Number(this.editedItem.carAllowance) +
                        Number(this.editedItem.mobileAllowance) +
                        Number(this.editedItem.otherAllowance) +
                        Number(this.editedItem.schooling);



                    Object.assign(this.items[this.editedIndex], this.editedItem)
                    this.EditTotalSTINonPerformanceDialog = false;
                    this.alertMsg = "ِPerformance-Non-related bonus updated successfully"
                    this.snackbar = true;

                }
            });



        },
        validateMapJob(item) {
            this.editedIndex = this.items.indexOf(item)
            this.editedItem = Object.assign({}, item)
            let data = {
                JobId: item.id,
            }

            this.$axios({
                method: 'get',
                url: `${this.$baseURL}/PositionMapping/MappedJob`,
                headers: { 'Content-Type': 'application/json' },
                params: data
            }).then((response) => {
                if (JSON.parse(response.data.status) == true) {
                    if (JSON.parse(response.data.valid) == true) {
                        Object.assign(this.items[this.editedIndex], this.editedItem)
                        this.alertMsg = "ِPosition Mapped Successfully"
                        this.snackbar = true;
                        if (this.selectedCustomer?.customerNumber > 0) {
                            this.loadCustomerData(this.selectedCustomer?.customerNumber);
                        } else {
                            this.loadCustomerData(this.customerNumber());
                        }
                    }
                    else {
                        this.alertMsg = "ِPosition can't be mapped"
                        this.snackbar = true;
                    }

                }

            });
        },
        validateTotalSTIPerformance() {
            if (this.$refs.STIPerformanceForm.validate()) {
                this.updateSTIPerformance();
            }

        },
        updateSTIPerformance() {

            let data = {
                Id: this.editedItem.id,
                CustomerNumber: this.editedItem.customerNumber,
                JobId: this.editedItem.jobId,
                SurveyYear: this.surveyYear,
                EOYBonus: this.editedItem.eoyBonus == null ? null : Number(this.editedItem.eoyBonus),
                EOYProfitShare: this.editedItem.eoyProfitShare == null ? null : Number(this.editedItem.eoyProfitShare),
                AnnualSalesCommission: this.editedItem.annualSalesCommission == null ? null : Number(this.editedItem.annualSalesCommission),
                ProjectBasedBonus: this.editedItem.projectBasedBonus == null ? null : Number(this.editedItem.projectBasedBonus),
                ProductivityBasedBonus: this.editedItem.productivityBasedBonus == null ? null : Number(this.editedItem.productivityBasedBonus),
            }
            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/PositionMapping/PerformanceRelatedBonus`,
                headers: { 'Content-Type': 'application/json' },
                data: data
            }).then((response) => {
                if (JSON.parse(response.data.status) == true) {
                    this.editedItem.id = response.data.updatedRecord;

                    this.editedItem.totalSTI =
                        Number(this.editedItem.eoyBonus) +
                        Number(this.editedItem.eoyProfitShare) +
                        Number(this.editedItem.annualSalesCommission) +
                        Number(this.editedItem.projectBasedBonus) +
                        Number(this.editedItem.productivityBasedBonus) +
                        Number(this.editedItem.annualSeasonalBonus) +
                        Number(this.editedItem.otherBonus);

                    this.editedItem.totalSTIValueEGP =
                        this.editedItem.grossBasicSalary *
                        (
                            Number(this.editedItem.eoyBonus) +
                            Number(this.editedItem.eoyProfitShare) +
                            Number(this.editedItem.annualSalesCommission) +
                            Number(this.editedItem.projectBasedBonus) +
                            Number(this.editedItem.productivityBasedBonus) +
                            Number(this.editedItem.annualSeasonalBonus) +
                            Number(this.editedItem.otherBonus)
                        ) / 100;

                    console.log(' this.editedItem.totalSTIValueEGP ')
                    console.log(this.editedItem.totalSTIValueEGP)

                    this.editedItem.annualTotalTargetPayValueEGP =
                        Number(this.editedItem.grossBasicSalary) *
                        (
                            Number(this.editedItem.stockOptionsPlan) +
                            Number(this.editedItem.retentionSharesPlan) +
                            Number(this.editedItem.tenureOfServiceBonus) +
                            Number(this.editedItem.employeeSavingPlan)
                        ) / 100 +
                        Number(this.editedItem.grossBasicSalary) *
                        (
                            Number(this.editedItem.eoyBonus) +
                            Number(this.editedItem.eoyProfitShare) +
                            Number(this.editedItem.annualSalesCommission) +
                            Number(this.editedItem.projectBasedBonus) +
                            Number(this.editedItem.productivityBasedBonus) +
                            Number(this.editedItem.annualSeasonalBonus) +
                            Number(this.editedItem.otherBonus)
                        ) / 100 +
                        Number(this.editedItem.grossBasicSalary) +
                        Number(this.editedItem.natureOfJobAllowance) +
                        Number(this.editedItem.representationAllowance) +
                        Number(this.editedItem.housingRentAllowance) +
                        Number(this.editedItem.transportaionAllowance) +
                        Number(this.editedItem.carAllowance) +
                        Number(this.editedItem.mobileAllowance) +
                        Number(this.editedItem.otherAllowance) +
                        Number(this.editedItem.schooling);



                    Object.assign(this.items[this.editedIndex], this.editedItem)
                    this.EditTotalSTIPerformanceDialog = false;
                    this.alertMsg = "ِPerformance-related bonus updated successfully"
                    this.snackbar = true;

                }
            });

        },
        validateTotalAllowance() {
            if (this.$refs.totalAllowanceForm.validate()) {
                this.updateTotalAllowance();
            }
        },
        updateTotalAllowance() {
            let data = {
                Id: this.editedItem.id,
                CustomerNumber: this.editedItem.customerNumber,
                JobId: this.editedItem.jobId,
                SurveyYear: this.surveyYear,
                NatureOfJobAllowance: this.editedItem.natureOfJobAllowance == null ? null : Number(this.editedItem.natureOfJobAllowance),
                RepresentationAllowance: this.editedItem.representationAllowance == null ? null : Number(this.editedItem.representationAllowance),
                HousingRentAllowance: this.editedItem.housingRentAllowance == null ? null : Number(this.editedItem.housingRentAllowance),
                TransportaionAllowance: this.editedItem.transportaionAllowance == null ? null : Number(this.editedItem.transportaionAllowance),
                CarAllowance: this.editedItem.carAllowance == null ? null : Number(this.editedItem.carAllowance),
                MobileAllowance: this.editedItem.mobileAllowance == null ? null : Number(this.editedItem.mobileAllowance),
                OtherAllowance: this.editedItem.otherAllowance == null ? null : Number(this.editedItem.otherAllowance),
            }

            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/PositionMapping/ActualAllowance`,
                headers: { 'Content-Type': 'application/json' },
                data: data
            }).then((response) => {
                console.log('response.................')
                console.log(response)
                if (response.data.status == true) {



                    this.editedItem.id = response.data.updatedRecord;


                    this.updateRecord();
                    console.log('this.editedIndex')
                    console.log(this.editedIndex)
                    Object.assign(this.items[this.editedIndex], this.editedItem)
                    this.calculateTotalAllowance;
                    this.EditTotalAllowanceDialog = false;
                    this.alertMsg = "ِAllowance updated successfully"
                    this.snackbar = true;
                }
            });
        },
        updateRecord() {
            this.editedItem.annualTotalGuaranteedPay =
                Number(this.editedItem.grossBasicSalary) +
                Number(this.editedItem.natureOfJobAllowance) +
                Number(this.editedItem.representationAllowance) +
                Number(this.editedItem.housingRentAllowance) +
                Number(this.editedItem.transportaionAllowance) +
                Number(this.editedItem.carAllowance) +
                Number(this.editedItem.mobileAllowance) +
                Number(this.editedItem.otherAllowance) +
                Number(this.editedItem.schooling);

            this.editedItem.annualTotalTargetPayValueEGP =
                Number(this.editedItem.grossBasicSalary) *
                (
                    Number(this.editedItem.stockOptionsPlan) +
                    Number(this.editedItem.retentionSharesPlan) +
                    Number(this.editedItem.tenureOfServiceBonus) +
                    Number(this.editedItem.employeeSavingPlan)
                ) / 100 +
                Number(this.editedItem.grossBasicSalary) *
                (
                    Number(this.editedItem.eoyBonus) +
                    Number(this.editedItem.eoyProfitShare) +
                    Number(this.editedItem.annualSalesCommission) +
                    Number(this.editedItem.projectBasedBonus) +
                    Number(this.editedItem.productivityBasedBonus) +
                    Number(this.editedItem.annualSeasonalBonus) +
                    Number(this.editedItem.otherBonus)
                ) / 100 +
                Number(this.editedItem.grossBasicSalary) +
                Number(this.editedItem.natureOfJobAllowance) +
                Number(this.editedItem.representationAllowance) +
                Number(this.editedItem.housingRentAllowance) +
                Number(this.editedItem.transportaionAllowance) +
                Number(this.editedItem.carAllowance) +
                Number(this.editedItem.mobileAllowance) +
                Number(this.editedItem.otherAllowance) +
                Number(this.editedItem.schooling);
        },
        departmentChanged() {
            this.allSubDepartments = [];
            let subDepartments =
                this.orgChartItems.filter(d => d.city != null && d.city === this.editedItem.workLocation
                    && d.department != null && d.department == this.editedItem.department)
            this.allSubDepartments = Array.from(new Set(subDepartments.map((item) => item.subDepartment)));
            this.allSubDepartments = this.allSubDepartments.filter(d => d != null);
        },
        workLocationChanged(item) {

            // console.log('item')
            // console.log(item)

            // console.log('this.orgChartItems')
            // console.log(this.orgChartItems)


            this.allDepartments = [];
            this.allSubDepartments = [];

            let cityDepartments = this.orgChartItems.filter(d => d.city != null && d.city === item);

            this.allDepartments = Array.from(new Set(cityDepartments.map((item) => item.department)));
            this.allDepartments = this.allDepartments.filter(d => d != null);

            //  this.allOrgLayers = Array.from(new Set(cityDepartments.map((item) => item.department)));

        },
        EditTotalLTI(item) {
            if (this.editedIndex < 0)
                this.editedIndex = this.items.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.EditTotalLTIeDialog = true
        },
        EditTotalSTINonPerformance(item) {
            if (this.editedIndex < 0)
                this.editedIndex = this.items.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.EditTotalSTINonPerformanceDialog = true
        },
        EditTotalSTIPerformance(item) {
            if (this.editedIndex < 0)
                this.editedIndex = this.items.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.EditTotalSTIPerformanceDialog = true
        },
        editTotalAllowance(item) {
            //  this.editedItem = null;
            if (this.editedIndex < 0)
                this.editedIndex = this.items.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.EditTotalAllowanceDialog = true
        },
        editBasicInfo(item) {
            this.editedIndex = this.items.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.workLocationChanged(this.editedItem.workLocation);
            this.departmentChanged();
            this.EditBasicInfoDialog = true;

        },
        validateState(name) {
            const { $dirty, $error } = this.$v.search[name];
            return $dirty ? !$error : null;
        },
        mapJobType(typeid) {
            if (typeid != null && typeid > 0) {
                return this.JobType.filter(item => item.value == typeid)[0].text
            }
            else
                return ''
        },
        BasicDataUpdateEditMode(item) {
            // console.log(item);
            this.isBasicDataEditMode = !this.isBasicDataEditMode
            this.positionAfterError = false;
            this.positionTypeNameError = false;
            this.positionDepartmentError = false;
            this.positionCountryError = false;
            if (Boolean(this.isBasicDataEditMode) == true) { //load lookups if not loaded from server

                if (this.customerLookups.filter(c => c.customerNumber == item.customerNumber).length == 0) { // then lookups are not loaded yet
                    this.$axios({
                        method: 'get',
                        url: `${this.$baseURL}/PositionMapping/BasicDataLookups`,
                        headers: { 'Content-Type': 'application/json' },
                        params: {
                            customerNumber: item.customerNumber,
                            surveyYear: this.surveyYear
                        }
                    })
                        .then((response) => {
                            this.customerLookups.push({
                                customerNumber: item.customerNumber,
                                details: {
                                    jobsList: response.data.reportsTo,
                                    departmentsList: response.data.departments,
                                    subDepartmentsList: response.data.subDepartments,
                                    organizationLayers: response.data.organizationLayers,
                                    jobGrades: response.data.jobGrades,
                                    workLocations: response.data.workLocation
                                }
                            });
                            console.log(this.customerLookups);
                        });
                }
            }
        },
        SetselectedCustomer(item) {
            this.selectedCustomer = item;
            this.loadCustomerData(item.customerNumber)
        },
        saveActualGrossPay() {
            if (!this.$refs.BasicInfoForm.validate()) {
                this.alertMsg = "ِPlease fill basic info first"
                this.snackbar = true;
                this.editedItem.actualGrossBasicPay = null;
                return;
            }
            if (this.$refs.ActualGrossPayForm.validate()) {

                let data = {
                    Id: this.editedItem.id,
                    CustomerNumber: this.editedItem.customerNumber,
                    SurveyYear: this.surveyYear,
                    GrossBasicSalary: this.editedItem.actualGrossBasicPay
                }
                this.$axios({
                    method: 'put',
                    url: `${this.$baseURL}/PositionMapping/BasicSalary`,
                    headers: { 'Content-Type': 'application/json' },
                    data: data
                })
                    .then((response) => {
                        if (JSON.parse(response.data.status) == true) {
                            this.editedItem.id = response.data.updatedRecord;
                            Object.assign(this.items[this.editedIndex], this.editedItem)
                            this.editedIndex = -1;
                            this.$bvModal.msgBoxOk('Data updated successfully', {
                                title: 'Confirmation',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'success',
                                headerClass: 'p-2 border-bottom-0',
                                footerClass: 'p-2 border-top-0',
                                centered: true
                            });
                            this.EditBasicInfoDialog = false;
                            if (this.selectedCustomer?.customerNumber > 0) {
                                this.loadCustomerData(this.selectedCustomer?.customerNumber);
                            } else {
                                this.loadCustomerData(this.customerNumber());
                            }
                        }
                    });

            }
        },
        SaveBasicData() {

            let data = {
                Id: this.editedItem.id,
                CustomerNumber: this.editedItem.customerNumber,
                SurveyYear: this.surveyYear,
                JobTypedId: this.editedItem.jobType,
                JobId: this.editedItem.jobId,
                PositionAfter: this.editedItem.mappedJob,
                // ReportTo: this.editedItem.reportsTo,
                Department: this.editedItem.department,
                SubDepartment: this.editedItem.subDepartment,
                // OrgLayer:this.editedItem.orgLayer,
                JobGrade: this.editedItem.jobGrade,
                WorkLocation: this.editedItem.workLocation,
                CompanyYearsOfExperience: Number(this.editedItem.totalExperienceYearsInCompany),
                CompanyJobYearsOfExperience: Number(this.editedItem.totalExperienceYearsInJobInCompany),
                JobYearsOfExperienceBefore: Number(this.editedItem.totalExperienceYearsInJobBeforeCompany)
            }
            console.log('console.log(data)')
            console.log(data)
            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/PositionMapping/BasicData`,
                headers: { 'Content-Type': 'application/json' },
                data: data
            })
                .then((response) => {
                    if (JSON.parse(response.data.status) == true) {
                        this.editedItem.id = response.data.updatedRecord;
                        Object.assign(this.items[this.editedIndex], this.editedItem)
                        this.$bvModal.msgBoxOk('Data updated successfully', {
                            title: 'Confirmation',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'success',
                            headerClass: 'p-2 border-bottom-0',
                            footerClass: 'p-2 border-top-0',
                            centered: true
                        });
                        this.EditBasicInfoDialog = false;
                        this.editedIndex = -1;

                    }
                });

        },
        BasiSalaryUpdateEditMode() {
            this.isBasicSalaryEditMode = !this.isBasicSalaryEditMode
            this.basicSalaryError = false;
        },
        SaveBasicSalary(item) {
            //  console.log(item.details.grossBasicSalary)
            if (item.details.grossBasicSalary == null) {
                this.basicSalaryError = true;
                return;
            }
            this.basicSalaryError = false;

            let data = {
                Id: item.id,
                CustomerNumber: item.customerNumber,
                SurveyYear: this.surveyYear,
                GrossBasicSalary: Number(item.details.grossBasicSalary)

            }
            // console.log(data)
            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/PositionMapping/BasicSalary`,
                headers: { 'Content-Type': 'application/json' },
                data: data
            })
                .then((response) => {
                    if (JSON.parse(response.data.status) == true) {
                        item.id = response.data.updatedRecord;
                        Object.assign(this.items[this.editedIndex], this.editedItem)
                        this.$bvModal.msgBoxOk('Data updated successfully', {
                            title: 'Confirmation',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'success',
                            headerClass: 'p-2 border-bottom-0',
                            footerClass: 'p-2 border-top-0',
                            centered: true
                        });
                        this.isBasicSalaryEditMode = false;
                        this.editedIndex = -1;

                    }
                });

        },
        ActualAnnualAllowanceUpdateEditMode() {
            this.isActualAnnualAllowanceEditMode = !this.isActualAnnualAllowanceEditMode
        },
        SaveActualAnnualAllowance(item) {
            let data = {
                Id: item.id,
                CustomerNumber: item.customerNumber,
                SurveyYear: this.surveyYear,
                NatureOfJobAllowance: item.details.natureOfJobAllowance == null ? null : Number(item.details.natureOfJobAllowance),
                RepresentationAllowance: item.details.representationAllowance == null ? null : Number(item.details.representationAllowance),
                HousingRentAllowance: item.details.housingRentAllowance == null ? null : Number(item.details.housingRentAllowance),
                TransportaionAllowance: item.details.transportaionAllowance == null ? null : Number(item.details.transportaionAllowance),
                CarAllowance: item.details.carAllowance == null ? null : Number(item.details.carAllowance),
                MobileAllowance: item.details.mobileAllowance == null ? null : Number(item.details.mobileAllowance),
                OtherAllowance: item.details.otherAllowance == null ? null : Number(item.details.otherAllowance),
            }

            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/PositionMapping/ActualAllowance`,
                headers: { 'Content-Type': 'application/json' },
                data: data
            }).then((response) => {
                if (JSON.parse(response.data.status) == true) {
                    item.id = response.data.updatedRecord;
                    Object.assign(this.items[this.editedIndex], this.editedItem)
                    this.$bvModal.msgBoxOk('Data updated successfully', {
                        title: 'Confirmation',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'success',
                        headerClass: 'p-2 border-bottom-0',
                        footerClass: 'p-2 border-top-0',
                        centered: true
                    });
                    this.isActualAnnualAllowanceEditMode = false;
                    this.editedIndex = -1;

                }
            });
        },
        PerformanceRelatedBonusUpdateEditMode() {
            this.isPerformanceRelatedBonusEditMode = !this.isPerformanceRelatedBonusEditMode
        },
        SavePerformanceRelatedBonus(item) {
            let data = {
                Id: item.id,
                CustomerNumber: item.customerNumber,
                SurveyYear: this.surveyYear,
                EOYBonus: item.details.eOYBonus == null ? null : Number(item.details.eOYBonus),
                EOYProfitShare: item.details.eOYProfitShare == null ? null : Number(item.details.eOYProfitShare),
                AnnualSalesCommission: item.details.annualSalesCommission == null ? null : Number(item.details.annualSalesCommission),
                ProjectBasedBonus: item.details.projectBasedBonus == null ? null : Number(item.details.projectBasedBonus),
                ProductivityBasedBonus: item.details.productivityBasedBonus == null ? null : Number(item.details.productivityBasedBonus),
            }

            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/PositionMapping/PerformanceRelatedBonus`,
                headers: { 'Content-Type': 'application/json' },
                data: data
            }).then((response) => {
                if (JSON.parse(response.data.status) == true) {
                    item.id = response.data.updatedRecord;
                    Object.assign(this.items[this.editedIndex], this.editedItem)
                    this.$bvModal.msgBoxOk('Data updated successfully', {
                        title: 'Confirmation',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'success',
                        headerClass: 'p-2 border-bottom-0',
                        footerClass: 'p-2 border-top-0',
                        centered: true
                    });
                    this.isPerformanceRelatedBonusEditMode = false;
                    this.editedIndex = -1;

                }
            });
        },
        NonPerformanceRelatedBonusUpdateEditMode() {
            this.isNonPerformanceRelatedBonusEditMode = !this.isNonPerformanceRelatedBonusEditMode;
        },
        SaveNonPerformanceRelatedBonus(item) {
            let data = {
                Id: item.id,
                CustomerNumber: item.customerNumber,
                SurveyYear: this.surveyYear,
                AnnualSeasonalBonus: item.details.annualSeasonalBonus == null ? null : Number(item.details.annualSeasonalBonus),
                OtherBonus: item.details.otherBonus == null ? null : Number(item.details.otherBonus),
            }

            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/PositionMapping/NonPerformanceRelatedBonus`,
                headers: { 'Content-Type': 'application/json' },
                data: data
            }).then((response) => {
                if (JSON.parse(response.data.status) == true) {
                    item.id = response.data.updatedRecord;
                    Object.assign(this.items[this.editedIndex], this.editedItem)
                    this.$bvModal.msgBoxOk('Data updated successfully', {
                        title: 'Confirmation',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'success',
                        headerClass: 'p-2 border-bottom-0',
                        footerClass: 'p-2 border-top-0',
                        centered: true
                    });
                    this.isNonPerformanceRelatedBonusEditMode = false;
                    this.editedIndex = -1;

                }
            });
        },
        LongTermIncentiveUpdateEditMode() {
            this.isLongTermIncentiveEditMode = !this.isLongTermIncentiveEditMode;
        },
        SaveLongTermIncentive(item) {
            let data = {
                Id: item.id,
                CustomerNumber: item.customerNumber,
                SurveyYear: this.surveyYear,
                StockOptionsPlan: item.details.stockOptionsPlan == null ? null : Number(item.details.stockOptionsPlan),
                retentionSharesPlan: item.details.retentionSharesPlan == null ? null : Number(item.details.retentionSharesPlan),
                TenureOfServiceBonus: item.details.tenureOfServiceBonus == null ? null : Number(item.details.tenureOfServiceBonus),
                Schooling: item.details.schooling == null ? null : Number(item.details.schooling),
                EmployeeSavingPlan: item.details.employeeSavingPlan == null ? null : Number(item.details.employeeSavingPlan),

            }

            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/PositionMapping/LongTermIncentive`,
                headers: { 'Content-Type': 'application/json' },
                data: data
            }).then((response) => {
                if (JSON.parse(response.data.status) == true) {
                    item.id = response.data.updatedRecord;
                    Object.assign(this.items[this.editedIndex], this.editedItem)
                    this.$bvModal.msgBoxOk('Data updated successfully', {
                        title: 'Confirmation',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'success',
                        headerClass: 'p-2 border-bottom-0',
                        footerClass: 'p-2 border-top-0',
                        centered: true
                    });
                    this.isLongTermIncentiveEditMode = false;
                    this.editedIndex = -1;

                }
            });
        },
        WorkRelatedRewardsUpdateEditMode() {
            this.isWorkRelatedRewardsEditMode = !this.isWorkRelatedRewardsEditMode
        },
        SaveWorkRelatedRewards(item) {
            let data = {
                Id: item.id,
                CustomerNumber: item.customerNumber,
                SurveyYear: this.surveyYear,
                AnnualPaidVacation: item.details.annualPaidVacation == null ? null : Number(item.details.annualPaidVacation),
                Networking: item.details.networking == null ? null : Number(item.details.networking),
                AvgOvertimeHoursPerMonth: item.details.avgOvertimeHoursPerMonth == null ? null : Number(item.details.avgOvertimeHoursPerMonth),
                OvertimeRate: item.details.overtimeRate == null ? null : Number(item.details.overtimeRate),

            }

            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/PositionMapping/WorkRelatedRewards`,
                headers: { 'Content-Type': 'application/json' },
                data: data
            }).then((response) => {
                if (JSON.parse(response.data.status) == true) {
                    item.id = response.data.updatedRecord;
                    Object.assign(this.items[this.editedIndex], this.editedItem)
                    this.$bvModal.msgBoxOk('Data updated successfully', {
                        title: 'Confirmation',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'success',
                        headerClass: 'p-2 border-bottom-0',
                        footerClass: 'p-2 border-top-0',
                        centered: true
                    });
                    this.isWorkRelatedRewardsEditMode = false;
                    this.editedIndex = -1;

                }
            });
        },
        getPositions() //1: unmapped "default", 2:mapped, 3 both
        {
            this.search.selectedPositions = null;
            let selectedCustomerNumber = '';
            if (this.customerNumber == -1) {
                selectedCustomerNumber = this.search.customer;
            }
            else {
                selectedCustomerNumber = this.customerNumber
            }
            this.$axios({
                method: 'get',
                url: `${this.$baseURL}/PositionMapping/Position?CustomerNumber=`
                    + selectedCustomerNumber + "&SurveyYear=" + this.surveyYear + "&PositionType=" + this.search.positionIncluded,
                headers: { 'Content-Type': 'application/json' },
                data: {
                    CustomerNumber: this.customerNumber,
                    SurveyYear: this.surveyYear,
                    PositionType: this.search.positionIncluded,
                }
            })
                .then((response) => {
                    // console.log(response)
                    this.PositionsInSearch = response.data;
                });
        },
        PositionsCriteriaChanged(state) {
            this.PositionKeyword = '';
            this.getPositions(state);
        },
        PositionSearch() {
            // console.log(this.PositionsInSearch);
            let positionsList =
                this.PositionsInSearch.filter(p => this.search.selectedPositions.indexOf(p.name) >= 0);
            if (positionsList.length == 0) {
                positionsList = [];
            }
            this.$axios({
                headers: { 'Content-Type': 'application/json' },
                method: 'get',
                url: `${this.$baseURL}/PositionMapping/`,
                params: {
                    Positions: positionsList.map(p => p.id).toString(),
                    CustomerNumber: this.customerNumber,
                    SurveyYear: this.surveyYear,
                    PositionType: this.search.positionIncluded
                },
            })
                .then((response) => {
                    if (JSON.parse(response.data.status) == true) {
                        //  console.log(response.data)
                        this.MappedUnmapped = response.data.positions.map(p => ({
                            customerName: p.customerName,
                            jobName: p.jobName,
                            customerNumber: p.customerNumber,
                            id: p.id,
                            jobId: p.jobId,
                            details: {
                                positionTypeId: p.positionTypeId == -1 ? null : p.positionTypeId,
                                positionAfter: p.mappedPositionName,
                                reportsTo: p.reportsTo,
                                department: p.department,
                                subDepartment: p.subDepartment,
                                orgLayer: p.orgLayer,
                                jobGrade: p.jobGrade,
                                workLocation: p.workLocaiton,
                                companyYearsOfExperience: p.companyYearsOfExperience,
                                companyJobYearsOfExperience: p.companyJobYearsOfExperience,
                                jobYearsOfExperienceBefore: p.jobYearsOfExperienceBefore,
                                grossBasicSalary: p.grossBasicSalary,
                                natureOfJobAllowance: p.natureOfJobAllowance,
                                representationAllowance: p.representationAllowance,
                                housingRentAllowance: p.housingRentAllowance,
                                transportaionAllowance: p.transportaionAllowance,
                                carAllowance: p.carAllowance,
                                mobileAllowance: p.mobileAllowance,
                                otherAllowance: p.otherAllowance,
                                eOYBonus: p.eoyBonus ?? null,
                                eOYProfitShare: p.eoyProfitShare ?? null,
                                annualSalesCommission: p.annualSalesCommission ?? null,
                                projectBasedBonus: p.projectBasedBonus ?? null,
                                productivityBasedBonus: p.productivityBasedBonus ?? null,
                                annualSeasonalBonus: p.annualSeasonalBonus ?? null,
                                stockOptionsPlan: p.stockOptionsPlan ?? null,
                                retentionSharesPlan: p.retentionSharesPlan ?? null,
                                tenureOfServiceBonus: p.tenureOfServiceBonus ?? null,
                                schooling: p.schooling ?? null,
                                employeeSavingPlan: p.employeeSavingPlan ?? null,
                                annualPaidVacation: p.annualPaidVacation ?? null,
                                networking: p.networking ?? null,
                                avgOvertimeHoursPerMonth: p.avgOvertimeHoursPerMonth ?? null,
                                overtimeRate: p.overtimeRate ?? null,

                            }
                        }));
                        //  console.log(this.MappedUnmapped)
                    }
                    else {
                        this.MappedUnmapped = [];
                    }


                })

            // if(this.search.positionIncluded == 1) //unmapped
            // {

            //  this.MappedUnmapped =    positionsList.map(p=> ({
            //         id: p.id,
            //         name: p.name,
            //         details:{
            //             positionTypeId:p.positionTypeId,
            //             positionTypeName:p.positionTypeName,
            //             positionAfter:null,
            //             reportsTo:null,
            //             department:null,
            //             subDepartment:null,
            //             orgLayer:null,
            //             jobGrade:null,
            //             workLocation:null,
            //             companyYearsOfExperience:null,
            //             companyJobYearsOfExperience:null,
            //             jobYearsOfExperienceBefore:null
            //         }
            //     })
            //     ) ;

            // }
            // else{
            //     axios({
            //         headers: {'Content-Type': 'application/json' },
            //         method: 'get',
            //         url: 'http://localhost:55022/API/PositionMapping/',
            //          data: {
            //             positions: positionsList.map(p=>p.id),
            //             customer: this.customerNumber
            //         }

            //     })
            //     .then((response)=>{
            //          this.MappedUnmapped = response.data;
            //     })
            // }


            // if(this.PositionKeyword==null || this.PositionKeyword == ''){
            //     this.MappedUnmapped = this.PositionsInSearch;
            // }




        },
        getTotalLongTermIncentive() {
            let incentive =
                Number(this.form.stockOptionsPlan == null ? 0 : this.form.stockOptionsPlan) +
                Number(this.form.retentionSharesPlan == null ? 0 : this.form.retentionSharesPlan) +
                Number(this.form.tenureOfServiceBonus == null ? 0 : this.form.tenureOfServiceBonus) +
                Number(this.form.schooling == null ? 0 : this.form.schooling) +
                Number(this.form.employeeSavingPlan == null ? 0 : this.form.employeeSavingPlan)
            return incentive;
        },
        getTotalNonPerformanceBonus() {
            let bonus =
                Number(this.form.annualSeasonalBonus == null ? 0 : this.form.EOYBonus) +
                Number(this.form.otherBonus == null ? 0 : this.form.EOYProfitShare)
            return bonus;
        },
        getTotalPerformanceBonus() {
            let bonus =
                Number(this.form.EOYBonus == null ? 0 : this.form.EOYBonus) +
                Number(this.form.EOYProfitShare == null ? 0 : this.form.EOYProfitShare) +
                Number(this.form.annualSalesCommission == null ? 0 : this.form.annualSalesCommission) +
                Number(this.form.projectBasedBonus == null ? 0 : this.form.projectBasedBonus) +
                Number(this.form.productivityBasedBonus == null ? 0 : this.form.productivityBasedBonus)
            return bonus;
        },
        getTotalYearsOfExperience(row) {
            // console.log(item)
            if (row.item.details != null) {

                let experience =
                    Number(row.item.details.companyYearsOfExperience == null ? 0 : row.item.details.companyYearsOfExperience) +
                    Number(row.item.details.companyJobYearsOfExperience == null ? 0 : row.item.details.companyJobYearsOfExperience) +
                    Number(row.item.details.jobYearsOfExperienceBefore == null ? 0 : row.item.details.jobYearsOfExperienceBefore)
                return experience;
            }
            else {
                return 0;
            }

        },
        getTotalAllowance() {
            let allowance =
                Number(this.form.natureOfJob == null ? 0 : this.form.natureOfJob) +
                Number(this.form.representation == null ? 0 : this.form.representation) +
                Number(this.form.housingRent == null ? 0 : this.form.housingRent) +
                Number(this.form.transportation == null ? 0 : this.form.transportation) +
                Number(this.form.car == null ? 0 : this.form.car) +
                Number(this.form.mobileAllowance == null ? 0 : this.form.mobileAllowance) +
                Number(this.form.otherAllowance == null ? 0 : this.form.otherAllowance) +
                Number(this.form.schooling == null ? 0 : this.form.schooling)
            return allowance;
        },

        loadCustomerData(customerNumber) {

            let selectedCustomer = null;
            if (customerNumber == null) {
                selectedCustomer = this.customerNumber
            }
            else {
                selectedCustomer = customerNumber
            }


            // 1- get positions
            this.$axios({
                method: 'get',
                url: `${this.$baseURL}/PositionMapping/`,
                params: {
                    CustomerNumber: selectedCustomer,
                    SurveyYear: this.surveyYear,
                },
                headers: { 'Content-Type': 'application/json' },
            })
                .then((response) => {
                    if (JSON.parse(response.data.status) == true) {
                        console.log('response.data.positions')
                        console.log(response.data.positions)
                        this.items = response.data.positions.map(p => ({
                            customerNumber: p.customerNumber,
                            jobName: p.jobName,
                            jobId: p.jobId,
                            id: p.id,
                            mappedJob: p.mappedPositionName,
                            jobType: p.positionTypeId,
                            reportsTo: p.reportsTo,
                            workLocation: p.workLocaiton,
                            jobGrade: p.jobGrade,
                            department: p.department,
                            subDepartment: p.subDepartment,
                            totalExperienceYearsInCompany: p.companyYearsOfExperience,
                            totalExperienceYearsInJobInCompany: p.companyJobYearsOfExperience,
                            totalExperienceYearsInJobBeforeCompany: p.jobYearsOfExperienceBefore,
                            actualGrossBasicPay: p.grossBasicSalary,
                            natureOfJobAllowance: p.natureOfJobAllowance,
                            representationAllowance: p.representationAllowance,
                            housingRentAllowance: p.housingRentAllowance,
                            transportaionAllowance: p.transportaionAllowance,
                            carAllowance: p.carAllowance,
                            mobileAllowance: p.mobileAllowance,
                            otherAllowance: p.otherAllowance,
                            totalAllowances:
                                p.natureOfJobAllowance +
                                p.representationAllowance +
                                p.housingRentAllowance +
                                p.transportaionAllowance +
                                p.carAllowance +
                                p.mobileAllowance +
                                p.otherAllowance +
                                p.schooling,
                            grossBasicSalary: p.grossBasicSalary,
                            annualTotalGuaranteedPay:
                                p.grossBasicSalary +
                                p.natureOfJobAllowance +
                                p.representationAllowance +
                                p.housingRentAllowance +
                                p.transportaionAllowance +
                                p.carAllowance +
                                p.mobileAllowance +
                                p.otherAllowance +
                                p.schooling,
                            eoyBonus: p.eoyBonus,
                            eoyProfitShare: p.eoyProfitShare,
                            annualSalesCommission: p.annualSalesCommission,
                            projectBasedBonus: p.projectBasedBonus,
                            productivityBasedBonus: p.productivityBasedBonus,
                            totalSTIPerformanceRelated:
                                p.eoyBonus +
                                p.eoyProfitShare +
                                p.annualSalesCommission +
                                p.projectBasedBonus +
                                p.productivityBasedBonus,
                            annualSeasonalBonus: p.annualSeasonalBonus,
                            otherBonus: p.otherBonus,
                            totalSTINonPerformanceRelated:
                                p.annualSeasonalBonus +
                                p.otherBonus,
                            totalSTI:
                                p.eoyBonus +
                                p.eoyProfitShare +
                                p.annualSalesCommission +
                                p.projectBasedBonus +
                                p.productivityBasedBonus +
                                p.annualSeasonalBonus +
                                p.otherBonus,
                            totalSTIValueEGP:
                                p.grossBasicSalary *
                                (
                                    p.eoyBonus +
                                    p.eoyProfitShare +
                                    p.annualSalesCommission +
                                    p.projectBasedBonus +
                                    p.productivityBasedBonus +
                                    p.annualSeasonalBonus +
                                    p.otherBonus
                                ) / 100,
                            stockOptionsPlan: p.stockOptionsPlan,
                            retentionSharesPlan: p.retentionSharesPlan,
                            schooling: p.schooling,
                            tenureOfServiceBonus: p.tenureOfServiceBonus,
                            employeeSavingPlan: p.employeeSavingPlan,
                            totalLTI:
                                p.stockOptionsPlan +
                                p.retentionSharesPlan +
                                p.tenureOfServiceBonus +
                                p.employeeSavingPlan,
                            totalLTIValueEGP:
                                p.grossBasicSalary *
                                (
                                    p.stockOptionsPlan +
                                    p.retentionSharesPlan +
                                    p.tenureOfServiceBonus +
                                    p.employeeSavingPlan
                                ) / 100,
                            annualTotalTargetPayValueEGP:
                                p.grossBasicSalary *
                                (
                                    p.stockOptionsPlan +
                                    p.retentionSharesPlan +
                                    p.tenureOfServiceBonus +
                                    p.employeeSavingPlan
                                ) / 100 +
                                p.grossBasicSalary *
                                (
                                    p.eoyBonus +
                                    p.eoyProfitShare +
                                    p.annualSalesCommission +
                                    p.projectBasedBonus +
                                    p.productivityBasedBonus +
                                    p.annualSeasonalBonus +
                                    p.otherBonus
                                ) / 100 +
                                p.grossBasicSalary +
                                p.natureOfJobAllowance +
                                p.representationAllowance +
                                p.housingRentAllowance +
                                p.transportaionAllowance +
                                p.carAllowance +
                                p.mobileAllowance +
                                p.otherAllowance +
                                p.schooling,
                            isMapped: p.isMapped,
                            isAdminApproved: p.isAdminApproved,
                            isSubmittedForApprove: p.isSubmittedForApprove,
                        }));


                        // 2- get lookups
                        this.$axios({
                            method: 'get',
                            url: `${this.$baseURL}/CustomerProfile/OrgChart?customerNumber=${selectedCustomer}`,
                            headers: { 'Content-Type': 'application/json' },
                        })
                            .then((response) => {
                                this.orgChartItems = response.data;
                                var locations = this.orgChartItems.filter(i => i.city != null);
                                this.allWorkLocations =
                                    Array.from(new Set(locations.map((item) => item.city)));
                            });

                        //3-  load lookups
                        this.allPositions =
                            Array.from(new Set(this.items.map((item) => item.jobName)));
                    }
                    else {
                        this.MappedUnmapped = [];
                    }

                });


        }


    },
    mounted() {
        if (this.customerNumber == -1) { //then load cutomers
            this.$axios({
                method: 'get',
                url: `${this.$baseURL}/PositionMapping/Searchlookups`,
            })
                .then((response) => {
                    // console.log(response.data.customersList);
                    this.customerList = response.data.customersList;
                    this.newCustomerList = response.data.customersList
                        .map(customer => ({
                            text: customer.customerName,
                            icon: 'mdi-map-marker',
                            customerNumber: customer.customerNumber
                        }))
                    this.surveyYears = response.data.surveyYears;
                    this.search.surveyYear = this.surveyYear;
                    this.PositionsInSearch = response.data.unmappedPositions.map(p => ({
                        id: p.titleId,
                        name: p.titleName,
                        PositionTypeId: p.positionTypeId,
                        PositionTypeName: p.positionTypeName
                    }));
                });

            // this.getPositions(1)
        }
        else {
            this.loadCustomerData();
        }
    }
}
</script>

<style scoped>
.searchPart {
    margin: 2em;
}

.my-border {
    border-left: 2px solid black;
    border-right: 2px solid black;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
}
</style>